import styles from "./subscriber-section.module.css";
import { TextField } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import SocialMedia from "./social-media";
import { useState } from "react";
import { useSnackbar } from "notistack";

const SubscriberSection = (props) => {
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const isDesktop = props.isDesktop;
  const isDark = props.dark ?? false;

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      // Call your function here
      subscribeUser();
    }
  }

  const subscribeUser = async () => {
    // console.log(email);
    setDisabled(true);

    try {
      const payload = {
        email: email,
      };
      const api_url = process.env.API_URL;
      const response = await fetch(`${api_url}api/users/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const res = await response.json();
      if (response.ok) {
        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        enqueueSnackbar(res.message, { variant: "warning" });
      }
    } catch (error) {
      enqueueSnackbar("something went wrong", { variant: "warning" });
    }

    setDisabled(false);
    setEmail("");
  };

  return (
    <>
      <section className={styles.section}>
        <div className="center">
          <div>
            {/* <div className={styles.subscriber_form}>
              <TextField
                id="email-subs"
                onChange={handleChange}
                label="Your Email"
                variant="standard"
                onKeyPress={handleKeyPress}
                value={email}
                disabled={disabled}
                className={`${styles.subscriber_input} ${
                  isDark && styles.dark
                }`}
              />

              <BsArrowRight
                className={styles.subscriber_submit}
                onClick={subscribeUser}
              />
            </div> */}
            {isDesktop && (
              <>
                <div className={styles.title}>Let&apos;s Stay in Touch</div>

                <div style={{ marginTop: "50px" }}>
                  <SocialMedia dark={isDark} />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default SubscriberSection;
