import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";

export const socialMedia = [
  {
    name: "Facebook",
    icon: <FaFacebookF />,
    url_path: "https://www.facebook.com/Aurum-Lab-110322707154852/",
  },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    url_path: "https://www.instagram.com/aurum.lab/",
  },
  {
    name: "Tiktok",
    icon: <FaTiktok />,
    url_path: "https://www.tiktok.com/@aurum.lab?lang=en",
  },
  {
    name: "Twitter",
    icon: <FaTwitter />,
    url_path: "https://twitter.com/aurumlab",
  },
  {
    name: "Youtube",
    icon: <FaYoutube />,
    url_path: "https://www.youtube.com/channel/UCmkI4Mp5bIzHOq9xmdWhl3A",
  },
];
