import SubscriberSection from "./subscriber-section";
import styles from "./footer.module.css";
import SocialMedia from "./social-media";
import Link from "next/link";
import Image from "next/image";

import { PaymentList } from "../../lib/payment-lib";

const Footer = (props) => {
  const year = new Date().getFullYear();
  const isDesktop = props.isDesktop;
  const isMobile = props.isMobile;
  const isDark = props.dark ?? false;
  const payment_list = PaymentList();
  const list_logo = payment_list.flatMap((payment) => payment.logo);

  return (
    <>
      <footer className={`${styles.footer} ${isDark ? styles.dark : ""}`}>
        <SubscriberSection isDesktop={isDesktop} dark={isDark} />
        {isDesktop ? (
          <>
            <hr />
            <div className={styles.list_footer_content}>
              <div className={styles.section_footer_content_row}>
                <div className={styles.section_footer_content_column}>
                  <Link className={isDark ? "dark" : ""} href="/the-brand">
                    <div>About Aurum</div>
                  </Link>
                  <Link className={isDark ? "dark" : ""} href="/faqs">
                    <div>FAQs</div>
                  </Link>
                </div>
                <div className={styles.section_footer_content_column}>
                  <Link className={isDark ? "dark" : ""} href="/how-to-order">
                    <div>How To Order</div>
                  </Link>
                  <Link className={isDark ? "dark" : ""} href="/term-condition">
                    <div>terms & Condition</div>
                  </Link>
                </div>
                <div className={styles.section_footer_content_column}>
                  <Link
                    className={isDark ? "dark" : ""}
                    href="/ring-size-guide"
                  >
                    <div>Ring Size Guide</div>
                  </Link>
                  <Link className={isDark ? "dark" : ""} href="/track-order">
                    <div>Tracking your order</div>
                  </Link>
                </div>
                <div className={styles.section_footer_content_column}>
                  <Link className={isDark ? "dark" : ""} href="/contact-us">
                    <div>Contact Us</div>
                  </Link>
                  <Link
                    className={isDark ? "dark" : ""}
                    href="https://wa.me/6281329082603"
                    target="_blank"
                  >
                    <div>Partnership</div>
                  </Link>
                </div>
                <div className={styles.section_footer_content_column}>
                  <Link className={isDark ? "dark" : ""} href="/affiliate">
                    <div>Affiliate Program</div>
                  </Link>
                  <Link
                    className={isDark ? "dark" : ""}
                    href="https://careers.theaurumlab.com"
                    target="_blank"
                  >
                    <div>Career</div>
                  </Link>
                </div>
              </div>
              <div>&copy; {year} aurum lab</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.mobile_footer}>
              <div>&copy; {year} aurum lab</div>
              <div className={styles.social_media}>
                <SocialMedia dark={isDark} />
              </div>
            </div>
          </>
        )}
        <div>Payment Method</div>
        <div className={styles.payment_list}>
          {list_logo.map((logo, key) => (
            <div
              className={`${styles.img} ${isDark ? styles.dark : ""}`}
              key={key}
            >
              <Image
                className={`${styles.payment_img} ${isDark ? styles.dark : ""}`}
                src={`/img/payment/${logo}`}
                width={300}
                height={150}
                alt={logo}
              />
            </div>
          ))}
        </div>
      </footer>
    </>
  );
};
export default Footer;
